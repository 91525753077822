import * as React from 'react';

import styles from '../UserDetailTable.module.scss';

export interface UserDetailImageProps {
    data: UserDetailData;
}

const UserDetailImage: React.FC<UserDetailImageProps> = ({ data: item }) => (
  <td className={styles.userDetailImageBox}>
    <img
      className={styles.userDetailImage}
      src={item.image}
      alt={item.username}
    />
  </td>
);

export default UserDetailImage;
