export const countriesData = [
  {
    label: 'All countries',
    value: 'all',
  },
  {
    label: 'France',
    value: 'france',
  },
  {
    label: 'Germany',
    value: 'germany',
  },
  {
    label: 'Spain',
    value: 'spain',
  },
];
export const statusData = [
  {
    label: 'All status',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Not active',
    value: 'notActive',
  },
  {
    label: 'Analyzing user',
    value: 'analyzing',
  },
  {
    label: 'Fake account',
    value: 'fake',
  },
];
