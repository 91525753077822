import classNames from 'classnames';
import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import UserStatus from '../../enums';
import styles from '../UserDetailTable.module.scss';

export interface UserDetailPaymentProps {
  data: {
    status: string;
    payment: Array<Payment>;
  };
}

const UserDetailPayment: React.FC<UserDetailPaymentProps> = ({ data: item }) => {
  const isSuspicious = item.status === UserStatus.Suspicious;

  const isAnalyzing = item.status === UserStatus.Analyzing;

  const isActiveOrNotActive = (
    item.status === UserStatus.Active
    || item.status === UserStatus.NotActive
  );

  return (
    <>
      {isAnalyzing
        && (
          <td className={styles.paymentDescription} colSpan={3}>
            <span>
              <FormattedMessage id="components.dashboard.user_detail.user_table.payment.analyzing" />
            </span>
          </td>
        )}

      {isSuspicious
        && (
          <td className={classNames(styles.suspiciousCase, styles.paymentDescription)} colSpan={3}>
            <span>
              <FormattedMessage id="components.dashboard.user_detail.user_table.payment.suspicious" />
            </span>
          </td>
        )}

      {isActiveOrNotActive && item.payment.map((payment) => (
        <td key={payment.id} className={styles.paymentPaid}>
          <span className={styles.paymentAmount}>
            {payment.amount}
            <FormattedMessage id="components.dashboard.user_detail.user_table.payment.currency_sign" />
          </span>
          <span className={styles.paymentDate}>
            <FormattedMessage id="components.dashboard.user_detail.user_table.payment.paid_at" />
            {payment.date}
          </span>
        </td>
      ))}
    </>
  );
};

export default UserDetailPayment;
