import React from 'react';

import UserDetailTable from './UserDetailTable';
import UserDetailSearch from './UserDetailTable/UserDetailSearch/index';

import styles from './UserDetail.module.scss';

export interface UserDetailProps extends React.FC {
  Table: typeof UserDetailTable;
  SearchBox: typeof UserDetailSearch;
}

const UserDetail: UserDetailProps = ({ children }) => (
  <div className={`${styles.userDetail} container`}>
    {children}
  </div>
);

UserDetail.Table = UserDetailTable;
UserDetail.SearchBox = UserDetailSearch;

export default UserDetail;
