import classNames from 'classnames';
import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import UserStatus from '../../enums';
import styles from '../UserDetailTable.module.scss';

export interface UserDetailStatusProps {
  data: string;
}

const UserDetailStatus: React.FC<UserDetailStatusProps> = ({ data: status }) => {
  const statusClass = (): string => {
    switch (status) {
      case UserStatus.Analyzing:
        return styles.analyzing;
      case UserStatus.Active:
        return styles.active;
      case UserStatus.NotActive:
        return styles.notActive;
      case UserStatus.Suspicious:
        return styles.suspicious;
      default:
        return '';
    }
  };

  return (
    <td className={classNames(styles.status, statusClass())}>
      <span>
        {status === UserStatus.Analyzing
          ? <FormattedMessage id="components.dashboard.user_detail.analyzing_status_text" />
          : status}
      </span>
    </td>
  );
};

export default UserDetailStatus;
