import React from 'react';
import { Helmet } from 'react-helmet';

import UserDetail from 'components/Dashboard/UserDetail';
import userDetailMockData from 'components/Dashboard/UserDetail/mockData';
import Layout from 'components/layout';

const UserDetailPage: React.FC = () => (
  <Layout userLogin>
    <Helmet>
      <title>User detail</title>
      <meta name="og:title" content="User detail" />
      <meta name="twitter:title" content="User detail" />
      <body className="dashboard" />
    </Helmet>
    <UserDetail>
      <UserDetail.SearchBox />
      <UserDetail.Table data={userDetailMockData} />
    </UserDetail>
  </Layout>
);

export default UserDetailPage;
