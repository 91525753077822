import classNames from 'classnames';
import React from 'react';

import styles from './Pagination.module.scss';

export interface Props {
  page: number;
  totalPages: number;
  handlePagination: (page: number) => void;
}

export const PaginationComponent: React.FC<Props> = ({
  page,
  totalPages,
  handlePagination,
}) => (
  <div className={styles.pagination}>
    <div className={styles.paginationWrapper}>

      <button
        onClick={(): void => handlePagination(page - 1)}
        type="button"
        disabled={page === 1}
        className={classNames([styles.pageItem, styles.prevSides].join(' '))}
        aria-label="prev button"
      />

      <button
        onClick={(): void => handlePagination(1)}
        type="button"
        className={classNames(styles.pageItem, {
          [styles.active]: page === 1,
        })}
      >
        {1}
      </button>

      {page > 3 && <div className={styles.separator}>...</div>}

      {page === totalPages && totalPages > 3 && (
        <button
          onClick={(): void => handlePagination(page - 2)}
          type="button"
          className={styles.pageItem}
        >
          {page - 2}
        </button>
      )}

      {page > 2 && (
        <button
          onClick={(): void => handlePagination(page - 1)}
          type="button"
          className={styles.pageItem}
        >
          {page - 1}
        </button>
      )}

      {page !== 1 && page !== totalPages && (
        <button
          onClick={(): void => handlePagination(page)}
          type="button"
          className={[styles.pageItem, styles.active].join(' ')}
        >
          {page}
        </button>
      )}

      {page < totalPages - 1 && (
        <button
          onClick={(): void => handlePagination(page + 1)}
          type="button"
          className={styles.pageItem}
        >
          {page + 1}
        </button>
      )}

      {page === 1 && totalPages > 3 && (
        <button
          onClick={(): void => handlePagination(page + 2)}
          type="button"
          className={styles.pageItem}
        >
          {page + 2}
        </button>
      )}

      {page < totalPages - 2 && <div className={styles.separator}>...</div>}

      <button
        onClick={(): void => handlePagination(totalPages)}
        type="button"
        className={classNames(styles.pageItem, {
          [styles.active]: page === totalPages,
        })}
      >
        {totalPages}
      </button>

      <button
        onClick={(): void => handlePagination(page + 1)}
        type="button"
        disabled={page === totalPages}
        className={[styles.pageItem, styles.nextSides].join(' ')}
        aria-label="next button"
      />

    </div>
  </div>
);
export const Pagination = PaginationComponent;
