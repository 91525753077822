const userDetailMockData = [
  {
    id: '1',
    image: 'https://i.pravatar.cc/200?img=47',
    name: 'Alta Hanson',
    username: '@alta_hanson',
    status: 'active',
    type: '1st type',
    album: 'Friends trip',
    payment: [
      {
        id: '1',
        amount: 0.50,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '2',
        amount: 1.00,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '3',
        amount: 1.50,
        date: '4/03/2020',
        status: 'paid',
      },
    ],
  },
  {
    id: '2',
    image: 'https://i.pravatar.cc/200?img=49',
    name: 'Alta Hanson',
    username: '@alta_hanson',
    status: 'analyzing',
    type: '',
    album: 'Friends trip',
    payment: [
      {
        id: '1',
        amount: 0.50,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '2',
        amount: 1.00,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '3',
        amount: 1.50,
        date: '4/03/2020',
        status: 'paid',
      },
    ],
  },
  {
    id: '3',
    image: 'https://i.pravatar.cc/200?img=52',
    name: 'Alta Hanson',
    username: '@alta_hanson',
    status: 'not active',
    type: '1st type',
    album: 'Friends trip',
    payment: [
      {
        id: '1',
        amount: 0.50,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '2',
        amount: 1.00,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '3',
        amount: 1.50,
        date: '4/03/2020',
        status: 'paid',
      },
    ],
  },
  {
    id: '4',
    image: 'https://i.pravatar.cc/200?img=57',
    name: 'Alta Hanson',
    username: '@alta_hanson',
    status: 'suspicious accounts',
    type: '1st type',
    album: 'Friends trip',
    payment: [
      {
        id: '1',
        amount: 0.50,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '2',
        amount: 1.00,
        date: '4/03/2020',
        status: 'paid',
      },
      {
        id: '3',
        amount: 1.50,
        date: '4/03/2020',
        status: 'paid',
      },
    ],
  },
];

export default userDetailMockData;
