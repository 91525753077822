import * as React from 'react';

import styles from '../UserDetailTable.module.scss';

export interface UserDetailUsernameProps {
    data: {
        username: string;
        name: string;
    };
}

const UserDetailUsername: React.FC<UserDetailUsernameProps> = ({ data: item }) => (
  <td>
    <span className={styles.userDetailName}>{item.name}</span>
    <span className={styles.userDetailUsername}>{item.username}</span>
  </td>
);

export default UserDetailUsername;
