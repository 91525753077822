import React, { useState } from 'react';

import { Pagination } from 'components/UI/Pagination';

import UserDetailFilter from './UserDetailFilter';
import UserDetailImage from './UserDetailImage';
import UserDetailPayment from './UserDetailPayment';
import UserDetailStatus from './UserDetailStatus';
import UserDetailUsername from './UserDetailUsername';

import styles from './UserDetailTable.module.scss';

export interface UserDetailTableProps {
  data: Array<UserDetailData>;
}

const UserDetailTable: React.FC<UserDetailTableProps> = ({ data }) => {
  const [filteredItems, setFilteredItems] = useState({});

  const [pagination, setPagination] = useState({
    page: 1,
    totalPage: 10,
  });

  const handleFilterChange = (item: FilterItems): void => {
    setFilteredItems({ ...filteredItems, [item.name]: item.value });
  };

  const handlePagination = (page: number): void => setPagination({ ...pagination, page });

  return (
    <div className={`${styles.tableWrap} row`}>
      <table className={styles.userDetailTable}>
        <UserDetailFilter handleChange={handleFilterChange} />
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <UserDetailImage data={item} />
              <UserDetailUsername data={{ username: item.username, name: item.name }} />
              <UserDetailStatus data={item.status} />
              <td>{item.type ? item.type : '-'}</td>
              <td>{item.album}</td>
              <UserDetailPayment data={{ status: item.status, payment: item.payment }} />
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        handlePagination={handlePagination}
        page={pagination.page}
        totalPages={pagination.totalPage}
      />
    </div>
  );
};

export default UserDetailTable;
