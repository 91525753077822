import * as React from 'react';

import Input from 'components/UI/Input/input';

import styles from '../UserDetailTable.module.scss';

const UserDetailSearch: React.FC = () => (
  <div className="row justify-content-end mb-3">
    <Input className={styles.searchInput} placeholder="Search" />
  </div>
);

export default UserDetailSearch;
