import React from 'react';

import SelectBox from 'components/UI/SelectBox';

import { countriesData, statusData } from './dataMock';

export interface UserDetailFilterProps {
    handleChange?: (item: SelectBoxHandleChange) => void;
}

const UserDetailFilter: React.FC<UserDetailFilterProps> = ({ handleChange }) => (
  <thead>
    <tr>
      <th colSpan={2}>
        <SelectBox
          name="countries"
          title="User by countires"
          items={countriesData}
          handleChange={handleChange}
        />
      </th>
      <th>
        <SelectBox
          name="status"
          title="User status"
          items={statusData}
          handleChange={handleChange}
        />
      </th>
      <th>
        <SelectBox
          name="types"
          title="User payments types"
          items={statusData}
          handleChange={handleChange}
        />
      </th>
      <th>
        <SelectBox
          name="album"
          title="User album access"
          items={statusData}
          handleChange={handleChange}
        />
      </th>
      <th colSpan={3}>
        <SelectBox
          name="payment"
          title="User payment by time"
          items={statusData}
          handleChange={handleChange}
        />
      </th>
    </tr>
  </thead>
);

export default UserDetailFilter;
